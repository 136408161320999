<template>
  <div class="row margin-0">
    <div class="col-6 pad-left-0">
      <div class="info-block">
        <input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('first_name')===-1" @change="handleUpdateSelection($event)" id="first_name" > <label for="">First Name</label>
        <p>{{ user.first_name }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block">
        <input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('last_name')===-1" @change="handleUpdateSelection($event)" id="last_name" > <label for="">Last Name</label>
        <p>{{ user.last_name }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block">
        <label for="">Email</label>
        <p>{{ user.email }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block">
        <label for="">Mobile</label>
        <p>{{ user.mobile }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block">
        <input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('gender')===-1" @change="handleUpdateSelection($event)" id="gender" > <label for="">Gender</label>
        <p>{{ user.gender || "-" }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block">
        <input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('region')===-1" @change="handleUpdateSelection($event)" id="region" > <label for="">Currency</label>
        <p>{{ user.region }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('mode')===-1" id="mode" @change="handleUpdateSelection($event)" > <label for="">Teaching Mode</label><p>{{ user.mode || "-" }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('demo')===-1" id="demo" @change="handleUpdateSelection($event)" > <label for="">Free Demo</label><p>{{ user.demo || "-" }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="false && mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('entity_type')===-1" id="entity_type" @change="handleUpdateSelection($event)" > <label for="">Entity Type</label><p>{{ user.entity_type || "-" }}</p>
      </div>
    </div>
    <div class="col-12 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('demo_preference')===-1" id="demo_preference" @change="handleUpdateSelection($event)" > <label for="">Demo Preference</label><p>{{ user.demo_preference || "-" }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('dob')===-1" id="dob" @change="handleUpdateSelection($event)" > <label for="">Date of Birth</label><p>{{ user.dob ? $filters.fullDate(user.dob) : "-" }}</p>
      </div>
    </div>
    <div class="col-6 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('location')===-1" id="location" @change="handleUpdateSelection($event)" > <label for="">Location</label><p>{{ user.address ? user.address.name || user.address : "-" }}</p>
      </div>
    </div>
    <div class="col-12 pad-left-0">
      <div class="info-block"><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('description')===-1" id="description" @change="handleUpdateSelection($event)" > <label for="">About</label><p>{{ user.description || "-" }}</p>
      </div>
    </div>
    <div class="col-12 pad-0">
      <label for=""><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('credentials')===-1" id="credentials" @change="handleUpdateSelection($event)" > Credentials</label>
      <h5>Education</h5>
      <template v-if="user.credentials && user.credentials.education">
        <template v-for="(item, idx) in user.credentials.education" :key="idx">
          <div class="row margin-0">
            <div class="col-5 pad-left-0">
              <p>- {{ item.title }}</p>
              <p>
                <a
                  v-if="item.file && item.file.url"
                  class="text-success"
                  :href="item.file.url"
                  target="_blank"
                  >{{ item.file.name || "File" }}</a
                ><span v-else>-</span>
              </p>
            </div>
          </div>
        </template>
      </template>
      <h5>Experience</h5>
      <template v-if="user.credentials && user.credentials.education">
        <template v-for="(item, idx) in user.credentials.experience" :key="idx">
          <div class="row margin-0">
            <div class="col-5 pad-left-0">
              <p>- {{ item.title }}</p>
              <p>
                <a
                  v-if="item.file && item.file.url"
                  class="text-success"
                  :href="item.file.url"
                  target="_blank"
                  >{{ item.file.name || "File" }}</a
                ><span v-else>-</span>
              </p>
            </div>
          </div>
        </template>
      </template>
      <h5>Certifications</h5>
      <template v-if="user.credentials && user.credentials.certification">
        <template
          v-for="(item, idx) in user.credentials.certification"
          :key="idx"
        >
          <div class="row margin-0">
            <div class="col-5 pad-left-0">
              <p>- {{ item.title }}</p>
              <p>
                <a
                  v-if="item.file && item.file.url"
                  class="text-success"
                  :href="item.file.url"
                  target="_blank"
                  >{{ item.file.name || "File" }}</a
                ><span v-else>-</span>
              </p>
            </div>
          </div>
        </template>
      </template>
      <h5><input type="checkbox" v-if="mode && mode==='PARTIAL'" name="" :checked="checkSelections.indexOf('data')===-1" id="data" @change="handleUpdateSelection($event)" > Social Links</h5>
      <template v-if="user.data">
        <div class="row margin-0">
          <div class="col-12 pad-left-0">
            <p class="margin-bottom-5">
              - Website: {{ user.data.website || "-" }}
            </p>
          </div>
          <div class="col-12 pad-left-0">
            <p class="margin-bottom-5">- Video: {{ user.data.video || "-" }}</p>
          </div>
          <div class="col-12 pad-left-0">
            <p class="margin-bottom-5">
              - Facebook: {{ user.data.facebook || "-" }}
            </p>
          </div>
          <div class="col-12 pad-left-0">
            <p class="margin-bottom-5">
              - LinkedIn: {{ user.data.linkedIn || "-" }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user", "selections", "mode"],
  data() {
    return {
      checkSelections: []
    }
  },
  created() {
    if(this.mode === 'PARTAIL') {
      // this.checkSelections = this.selections || [];
      this.checkSelections = [];
    } else {
      this.checkSelections = [];
    }
  },
  methods: {
    handleUpdateSelection(event) {
      const that = this;
      this.$nextTick(() => {
        let oldList = [...that.checkSelections];
        if(oldList.indexOf(event.target.id)===-1) {
          oldList.push(event.target.id);
        } else {

        }
        that.$emit('checkboxSelections', oldList);
        that.checkSelections = oldList;
      })
    }
  }
};
</script>