<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">Add New Setting</h4>
                <h4 v-if="payload.created_on" class="text-center">Update Action</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Action</b></label>
                      <p>{{payload.action}}</p>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select name="" id="" v-model="payload.status" class="form-control form-control-sm">
                        <template v-if="lookupsSelector && lookupsSelector.REQUEST_ACTION_STATUS">
                          <template v-for="(item, idx) in lookupsSelector.REQUEST_ACTION_STATUS.codes" :key="idx">
                            <option :value="item.code">{{item.description}}</option>
                          </template>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for=""><b>Feedback</b></label>
                      <textarea name="" v-model="payload.comments" class="form-control form-control-sm" placeholder="Comments here..." id="" cols="30" rows="10"></textarea>
                      <span class="error" v-if="error && error.comments">{{error.comments}}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.created_on"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Create Setting
                    </button>
                    <button
                      v-if="payload.created_on"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Action
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Requests &amp; Actions</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <!-- <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    class="form-control form-control-sm"
                  />
                </div>
              </div> -->
              <div class="col-2 pad-left-0">
                <div class="form-group">
                  <label for=""><b>Service Request</b></label>
                  <select name="" id="" v-model="request_type" class="form-control form-control-sm">
                    <option :value="null">All</option>
                    <template v-if="lookupsSelector && lookupsSelector.SERVICE_REQUEST_TYPE">
                      <template v-for="(item, idx) in lookupsSelector.SERVICE_REQUEST_TYPE.codes" :key="idx">
                        <option :value="item.code">{{item.description}}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2 pad-left-0">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select name="" id="" v-model="status" class="form-control form-control-sm">
                    <option :value="null">All</option>
                    <template v-if="lookupsSelector && lookupsSelector.REQUEST_ACTION_STATUS">
                      <template v-for="(item, idx) in lookupsSelector.REQUEST_ACTION_STATUS.codes" :key="idx">
                        <option :value="item.code">{{item.description}}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2 pad-left-0">
                <div class="form-group">
                  <label for=""><b>Request Date</b></label>
                  <input type="date" v-model="created_on" class="form-control form-control-sm">
                  <!-- <select name="" id="" v-model="status" class="form-control form-control-sm">
                    <option :value="null">All</option>
                    <template v-if="lookupsSelector && lookupsSelector.REQUEST_ACTION_STATUS">
                      <template v-for="(item, idx) in lookupsSelector.REQUEST_ACTION_STATUS.codes" :key="idx">
                        <option :value="item.code">{{item.description}}</option>
                      </template>
                    </template>
                  </select> -->
                </div>
              </div>
              <div class="col-2 pad-left-0">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="status=null;request_type=null;created_on=null;clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <!-- <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Setting
                  </button> -->
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>SERVICE REQUEST</th>
                  <th>CREATED BY</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'created_on' || sort_by == '-created_on',
                    }"
                    @click="sortBy('created_on')"
                  >
                    CREATED ON
                    <i
                      :class="{
                        'fa-sort': sort_by != 'created_on',
                        'fa-sort-up': sort_by == 'created_on',
                        'fa-sort-down': sort_by == '-created_on',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th>FEEDBACK</th>
                  <th>Action</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td @click="
                          selectedProduct === item.id
                            ? (selectedProduct = null)
                            : (selectedProduct = item.id)
                        ">
                        <span
                          class="fa txt-grey"
                          :class="{
                            'fa-chevron-right': selectedProduct !== item.id,
                            'fa-chevron-down': selectedProduct === item.id,
                          }"
                        ></span>
                        {{ item.action }}</td>
                      <td>{{ item.user_details }}</td>
                      <td>{{$filters.fullDate(item.created_on)}}</td>
                      <td>{{ item.comments || '-' }}</td>
                      <td>{{ item.response_action || '-' }}</td>
                      <td><status :status="item.status" /></td>
                    </tr>
                    <tr v-if="selectedProduct === item.id">
                      <td
                        colspan="6"
                        style="background-color: #f5f7fa"
                        class="pad-5"
                      >
                        <action-detail
                          @updated="getdataList()"
                          :action="{...item}"
                          :pay="{...item}"
                          :id="item.id"
                        ></action-detail>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionDetail from "./components/ActionDetail";
import DataMixin from "../mixins/dataMixin";
export default {
  components: {
    ActionDetail
  },
  data() {
    return {
      serviceModule: "action",
      selectedProduct: null,
      status: null,
      request_type: null,
      created_on: null,
      defaultPayload: {
        action: null,
        comments: 'APP',
        user: null,
        valid_upto: null,
        status: 'OPEN'
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    extraParams() {
      let params = {};
      if(this.status !== null) {
        params.status= this.status;
      }
      if(this.request_type !== null) {
        params.action= this.request_type;
      }
      if(this.created_on !== null) {
        params.on_date= this.created_on;
      }
      return params;
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (
        that.payload.id === null ||
        that.payload.id === ""
      ) {
        this.error.id = "id is required";
        proceed = false;
      }
      if (that.payload.value === null || that.payload.value === "") {
        this.error.value = "value is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>