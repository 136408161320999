<template>
	<div class="user-detail">
		<div class="row margin-0">
			<div class="col-md-12 pad-0">
				<div class="parent">
					<div class="parent-body">
                        <div class="row margin-0">
                            <div class="col pad-left-0">
                                <template v-if="action.action==='PROFILE_UPDATE'">
                                    <div class="row margin-0">
                                        <div class="col-6 pad-0">
                                            <h5>Old Profile</h5>
                                            <user-info v-if="actionData" :user="actionData" />
                                            <p v-else>Loading....</p>
                                        </div>
                                        <div class="col-6 pad-right-0" style="border-left: 1px dotted #ddd;border-right: 1px dotted #ddd;">
                                            <h5>New Profile</h5>
                                            <user-info v-if="payloadObj && payloadObj.respond_action==='PARTIAL_UPDATE'" :user="action.data" @checkboxSelections="handleUpdateSelection" :mode="'PARTIAL'" />
                                            <user-info v-else :user="action.data" :mode="null" />
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='ACCOUNT_CREATION'">
                                    <div class="row margin-0">
                                        <div class="col-6 pad-0">
                                            <h5>Profile Details</h5>
                                            <user-info v-if="actionData" :user="actionData" />
                                            <p v-else>Loading....</p>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='COIN_DISPUTE'">
                                    <div class="row margin-0">
                                        <div class="col-12 pad-0">
                                            <h5>Transaction Details</h5>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">User</label>
                                                <p>{{ action.user_details || '-' }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Dispute Reason</label>
                                                <p>{{ action.data.reason }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Transaction Type</label>
                                                <p>{{ action.data_details.transaction_type }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Transaction coins</label>
                                                <p>{{ action.data_details.coins }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Transaction for</label>
                                                <p>{{ action.data_details.message }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Transaction on</label>
                                                <p>{{ $filters.fullDate(action.data_details.created_on, "DD-MMM-YYYY, h:mm A") }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='SUBSCRIPTION_DISPUTE'">
                                    <div class="row margin-0">
                                        <div class="col-12 pad-0">
                                            <h5>Subscription Details</h5>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">User</label>
                                                <p>{{ action.user_details || '-' }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Dispute Reason</label>
                                                <p>{{ action.data.reason }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Plan</label>
                                                <p>{{ action.data_details.plan_details.title || '-' }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Plan status</label>
                                                <p>{{ action.data_details.status }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Plan start date</label>
                                                <p>{{ $filters.fullDate(action.data_details.start_date, "DD-MMM-YYYY") }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Plan end date</label>
                                                <p>{{ $filters.fullDate(action.data_details.end_date, "DD-MMM-YYYY") }}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Created on</label>
                                                <p>{{ $filters.fullDate(action.data_details.created_on, "DD-MMM-YYYY, h:mm A") }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label for="">Pricing:</label>
                                            <ul class="pad-left-15">
                                                <li>Sub Total: <b>{{action.data_details.pricing.amount}}</b></li>
                                                <li>Wallet: <b>{{action.data_details.pricing.wallet}}</b></li>
                                                <li>Discount: <b>{{action.data_details.pricing.discount}}</b></li>
                                                <li>Tax: <b>{{action.data_details.pricing.tax?action.data_details.pricing.tax.deductable:0}}</b></li>
                                                <li>Total: <b>{{action.data_details.pricing.total}}</b></li>
                                            </ul>
                                        </div>
                                        <div class="col-6">
                                            <label for="">Payment Details:</label>
                                            <ul class="pad-left-15">
                                                <li>Gateway: <b>{{action.data_details.payment.gateway}}</b></li>
                                                <li>Currency: <b>{{action.data_details.payment.currency}}</b></li>
                                                <li>Gateway Order Id: <b>{{action.data_details.payment.data.razorpay_order_id?action.data_details.payment.data.razorpay_order_id:'-'}}</b></li>
                                                <li>Gateway Payment Id: <b>{{action.data_details.payment.data.razorpay_payment_id?action.data_details.payment.data.razorpay_payment_id:'-'}}</b></li>
                                                <li><a href="http://razorpay.com" target="_blank" rel="noopener noreferrer">Visit Gateway</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='SUSPEND_ACCOUNT' || action.action==='CLOSE_ACCOUNT' || action.action==='ACTIVATE_ACCOUNT'">
                                    <div class="row margin-0">
                                        <div class="col-12 pad-0">
                                            <h5>User Details</h5>
                                            <user-info v-if="actionData" :user="actionData" />
                                            <p v-else>Loading....</p>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='UPDATE_THUMBNAIL'">
                                    <div class="row margin-0">
                                        <div class="col-6 pad-0">
                                            <h5>Old Thumbnail</h5>
                                            <img v-if="actionData && actionData.data_details && actionData.data_details.thumbnail" :src="actionData.data_details.thumbnail" style="max-width:40%;" alt="">
                                            <span v-else>No Image</span>
                                        </div>
                                        <div class="col-6 pad-right-0" style="border-left: 1px dotted #ddd;border-right: 1px dotted #ddd;">
                                            <h5>New Thumbnail</h5>
                                            <img v-if="action && action.data && action.data" :src="action.data" style="max-width:40%;" alt="">
                                            <span v-else>No Image</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='ASK_ADMIN'">
                                    <div class="row margin-0">
                                        <div class="col-12 pad-0">
                                            <div class="info-block">
                                                <label for="">User</label>
                                                <p>{{ action.data.comment || '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="action.action==='ADD_NEW_COURSE' || action.action==='SERVICE_REQUEST'">
                                    <div class="row margin-0">
                                        <div class="col-12 pad-0">
                                            <div class="info-block">
                                                <label for="">User</label>
                                                <p>{{ action.user_details }}</p>
                                            </div>
                                        </div>
                                        <div class="col-12 pad-0">
                                            <div class="info-block">
                                                <label for="">User Comments</label>
                                                <p>{{ action.data.comment || '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    {{action}}
                                </template>
                            </div>
                            <div class="col-2 pad-0" v-if="action.status!=='COMPLETED'&&action.status!=='REJECTED'">
                                <div class="row margin-0">
                                    <div class="col-12 pad-0">
                                        <div class="form-group">
                                        <label for=""><b>Response Action</b></label>
                                        <select
                                            name=""
                                            id=""
                                            v-model="payloadObj.respond_action"
                                            class="form-control form-control-sm"
                                        >
                                            <option :value="null">Nothing</option>
                                            <template v-if="action.action==='PROFILE_UPDATE'">
                                                <option value="UPDATE">Update Profile</option>
                                                <option value="PARTIAL_UPDATE">Partial Update</option>
                                            </template>
                                            <template v-if="action.action==='UPDATE_THUMBNAIL'">
                                                <option value="UPDATE">Updated Thumbnail</option>
                                            </template>
                                            <template v-if="action.action==='COIN_DISPUTE'">
                                                <option value="REFUND">REFUND</option>
                                            </template>
                                             <template v-if="action.action==='SUBSCRIPTION_DISPUTE'">
                                                <option value="REFUND">REFUND</option>
                                            </template>
                                            <template v-else-if="action.action==='ACCOUNT_CREATION' || action.action==='ACTIVATE_ACCOUNT'">
                                                <option value="MAKEACTIVE">MAKE ACTIVE</option>
                                            </template>
                                            <template v-else-if="action.action==='SUSPEND_ACCOUNT'">
                                                <option value="SUSPEND">SUSPEND ACCOUNT</option>
                                            </template>
                                            <template v-else-if="action.action==='CLOSE_ACCOUNT'">
                                                <option value="CLOSE">CLOSE ACCOUNT</option>
                                            </template>
                                        </select>
                                        </div>
                                    </div>
                                    <div class="col-12 pad-0">
                                        <div class="form-group">
                                        <label for=""><b>Status</b></label>
                                        <select name="" id="" v-model="payloadObj.status" class="form-control form-control-sm">
                                            <template v-if="lookupsSelector && lookupsSelector.REQUEST_ACTION_STATUS">
                                            <template v-for="(item, idx) in lookupsSelector.REQUEST_ACTION_STATUS.codes" :key="idx">
                                                <option :value="item.code">{{item.description}}</option>
                                            </template>
                                            </template>
                                        </select>
                                        </div>
                                    </div>
                                    <div class="col-12 pad-0" v-if="action.action==='COIN_DISPUTE' && payloadObj.respond_action==='REFUND'">
                                        <div class="form-group">
                                            <label for="">Coins</label>
                                            <input
                                                type="number"
                                                v-model="payloadObj.respond_value"
                                                class="form-control form-control-sm"
                                                placeholder="Enter Value"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 pad-0">
                                        <div class="form-group">
                                        <label for=""><b>Feedback</b></label>
                                        <textarea name="" v-model="payloadObj.comments" class="form-control form-control-sm" placeholder="Comments here..." id="" cols="30" rows="5"></textarea>
                                        <span class="error" v-if="error && error.comments">{{error.comments}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <button
                                            @click="decideAction(payloadObj)"
                                            class="btn btn-success btn-sm"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataMixin from "@/mixins/dataMixin";
import UserInfo from './UserInfo.vue';
export default {
    props: ['id', 'action', 'pay'],
    components: {
        UserInfo
    },
    data() {
        return {
            actionData: null,
            payloadObj: {}
        }
    },
    created() {
        this.payloadObj = {
            ...this.pay,
            respond_action: null
        }
        this.getdataList();
    },
    mixins: [DataMixin],
    methods: {
        getdataList() {
            const that = this;
            that.$cs.action.create({
                resource: `${that.$cs.action.api}${that.id}/details/`,
            }).then(
                (data) => {
                    that.actionData = data.data;
                },
                () => {
                    console.log("error");
                }
            );
        },
        decideAction() {
            const that = this;
            const finalPayload = {...that.payloadObj};
            that.$cs.action.create({
                resource: `${that.$cs.action.api}${that.id}/finalize/`,
                data: finalPayload
            }).then(
                (data) => {
                    that.$emit('updated');
                },
                () => {
                    console.log("error");
                }
            );
        },
        handleUpdateSelection(value) {
            console.log(value);
            this.payloadObj.respond_value = value;
        }
    }
}
</script>
